import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TruckyIcon from "../TruckyIcon";

export default function UserAttributes({ user, container }) {
  return (
    <div className="flex items-center">
      {user.steam_profile && (
        <a
          href={`http://steamcommunity.com/profiles/${user.steam_profile.steam_id}`}
          target="_blank"
          className="text-white me-5"
        >
          <FontAwesomeIcon
            icon={["fab", "steam"]}
            className="me-1"
          ></FontAwesomeIcon>
          {container.localeManager.strings.steamProfile}
        </a>
      )}
      {user.discord_profile && (
        <span className="me-5">
          <FontAwesomeIcon
            icon={["fab", "discord"]}
            className="me-1"
          ></FontAwesomeIcon>
          {user.discord_profile.discord_username}
        </span>
      )}
      {user.is_premium && (
        <span className="text-premium me-5">
          <FontAwesomeIcon icon="star" className="me-1"></FontAwesomeIcon>
          Premium
        </span>
      )}
      {user.is_ambassador && (
        <span className="text-primary me-5">
          <FontAwesomeIcon icon="shield" className="me-1"></FontAwesomeIcon>
          Ambassador
        </span>
      )}
      {user.is_staff && (
        <span className="text-success me-5">
          <TruckyIcon icon="logo" className="me-1"></TruckyIcon>
          Trucky Staff
        </span>
      )}
      {user.twitch && (
        <a className="me-5" href={user.twitch} target="_blank">
          <FontAwesomeIcon
            className="me-1"
            icon={["fab", "twitch"]}
          ></FontAwesomeIcon>
          Twitch
        </a>
      )}
      {user.facebook && (
        <a className="me-5" href={user.facebook} target="_blank">
          <FontAwesomeIcon
            className="me-1"
            icon={["fab", "facebook"]}
          ></FontAwesomeIcon>
          Facebook
        </a>
      )}
      {user.youtube && (
        <a className="me-5" href={user.youtube} target="_blank">
          <FontAwesomeIcon
            className="me-1"
            icon={["fab", "youtube"]}
          ></FontAwesomeIcon>
          Youtube
        </a>
      )}
      {user.wotr && (
        <a className="me-5" href={user.wotr} target="_blank">
          <FontAwesomeIcon
            className="me-1"
            icon="truck"
          ></FontAwesomeIcon>
          WOTR
        </a>
      )}
      {user.discord_server && (
        <a className="me-5" href={user.discord_server} target="_blank">
          <FontAwesomeIcon
            className="me-1"
            icon={['fab', 'discord']}
          ></FontAwesomeIcon>
          Discord Server
        </a>
      )}
    </div>
  );
}
